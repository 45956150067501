<template>
  <div class="">
    <div class="row mx-0 px-0">
      <div class="col-12 mx-0 px-0">
        <div class="card rounded-0">
          <div class="card-header border-bottom py-1">
              <div class="card-title text-warning flex-grow-1">
                Stores
              </div>
              <div v-if="isServingTenant" class="mr-half">
                <dx-util-text-box
                  v-if="hasPermission"
                  v-model="accountNo"
                  width="100"
                  :show-clear-button="true"
                  placeholder="Account No"
                  @key-down="onEmitAccountNo"
                  @value-changed="onEmitAccountNo"
                />
              </div>
              <div>
                <dx-util-button icon="refresh" @click="onEmitRefreshStores" />
              </div>
            </div>
            <dx-util-scroll-view show-scrollbar="onHover" height="calc(100vh - 205px)">
              <div>
                <div v-for="(store, index) in fbmStores" :key="index">
                  <div role="button" class="d-flex text-left p-1 align-items-center" :class="fbmSelectedStore == store.storeId ? 'bg-light-blue' : ''" @click="selectStore(store)">
                    <img style="height:24px;" class="pr-1" :src="store.platform.toLowerCase() !== 'all' ? require(`@/assets/images/svg/ecommerce/${store.platform.toLowerCase()}.svg`) : ''">
                    <div class="text-truncate">
                      <div class="text-uppercase">
                        {{ store.storeName }}
                      </div>
                      <span v-if="isServingTenant">
                        <small v-if="store.accountNo || store.companyName" class="">{{ store.accountNo }}-{{ store.companyName }}</small>
                      </span>
                    </div>
                    <div class="flex-grow-1"></div>
                    <div :class="store.orderCount === 0 ? 'bg-dark' : 'bg-success'" class="text-white rounded px-half ml-1">
                      {{ store.orderCount }}
                    </div>
                  </div>
                </div>
              </div>
            </dx-util-scroll-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCurrentUser from '@/libs/app/current-user'
import { v4 as uuidv4 } from 'uuid'
import useFbmState from '../useFbmStates'

export default {
  data() {
    return {
      accountNo: null,
    }
  },
  setup() {
    const {
      fbmStores, fbmSelectedStore, loadFbmStores, orderSelected, fbmOrderReloadNeeded,
    } = useFbmState()
    const { isServingTenant } = useCurrentUser()
    return {
      fbmStores,
      fbmSelectedStore,
      loadFbmStores,
      orderSelected,
      isServingTenant,
      fbmOrderReloadNeeded,
    }
  },
  computed: {
    currentRouteQuery() {
      return { ...this.$route.query }
    },
    storeIdParam() {
      return this.$route.query.storeId
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  async mounted() {
    const status = this.$route.query.status ?? 'AWAITING'
    await this.loadFbmStores(0, status)
    this.fbmSelectedStore = this.storeIdParam ?? 0
  },
  methods: {
    selectStore(store) {
      this.fbmOrderReloadNeeded = uuidv4()
      this.fbmSelectedStore = store.storeId
      this.orderSelected = false
      this.$router.replace({ query: { ...this.currentRouteQuery, storeId: this.fbmSelectedStore } }).catch(() => {})
    },
    onEmitAccountNo(e) {
      if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
      }
      this.$emit('emit-account-no', e)
    },
    onEmitRefreshStores() {
      this.$emit('emit-refresh-stores')
    },
  },

}
</script>

<style lang="scss" scoped>
.bg-light-blue {
  background-color: rgba(37, 92, 83, 0.3);
}
.title {
  color: black;
}
.subtitle {
  color: black
}
</style>
